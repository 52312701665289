$backgroundOverlay: rgba(18, 18, 18, 0.38);
$colorDisabled: rgba(255, 255, 255, 0.5);


$whiteText: #fff;
$mainBackroundColor: #201f1d;
$colorBackgroundHeader: #3c3b39;
$colorBackgroundHeaderPopup: #595959;
$colorBorderHeader: #5e5e5e;
$parerBackground: #3c3b39;
$colorOrange: #65bff2;
$blueColor: #65bff2;
$colorTextUserName: #727171;

$lineColor: #7b7b7b;

$borderColorTable: #3c3b39;


// Dark Mode //
.bpx-header-right {


  .MuiFormControlLabel-root {
    margin-right: 8px;
  }
  .MuiSwitch-root {
    margin: 0;
  }
  // .MuiSwitch-root .MuiSwitch-thumb {
  //   background-color: $blueColor;
  // }
}

.bpx-darkmode-switcher {
padding: 0 !important;
margin-right: 10px !important;

  svg {
    color: rgb(25, 118, 210);
  }
}


body.dark {
  background-color: $mainBackroundColor;
  color: $whiteText;


  //Popup in BP 
  .bpx-popupdialog-block {
    color: $whiteText;
  }
  .bpx-popupdialog-header {
    border-color: $whiteText;
  }
  .bpx-popupdialog-closeicon {
    color: $whiteText;
  }
  .bpx-popupdialog-btn {
    color: $whiteText;
    border-color: $blueColor;
  }

  
  .dialog-fonts-title {
    border-color: #fff;
  }

  .bpx-select-item.customOptionStatus {
    color: $whiteText;
  }
  .customOptionTable .customOptionLoading svg {
    color: $blueColor;
  }

  .subtotal-autoselect-list {
    color: #fff;
  }
  .subtotal-autoselect-list.selected {
    color: #000;
  }
  .subtotal-autoselect-list:hover {
    color: #000;
  }

  .bpx-refresh-block {
    color: #fff;
  }

  .bpx-refresh-footer .bpx-refresh-cancel {
    border-color: $blueColor;
    color: $blueColor;
    
  }
  .bpx-refresh-footer button:last-child {
    background-color: $blueColor;
  }

  // Accrual posting list
  .bpx-dialog-accstatus-block {
    color: $whiteText;
  }
  .bpx-dialog-accstatus-section {
    border-color: $whiteText;
  }
  .bpx-dialog-accstatus-option .bpx-dialog-accstatus-buttons {

      button:first-child {
        border-color: $blueColor;
        color: $blueColor;
      }
      button:last-child {
        background-color: $blueColor;
        color: $whiteText;
        border-color: $blueColor;
      }
  }

  .bpx-dialog-status-bg .bpx-dialog-status-footer {

      button:first-child {
        border-color: $blueColor;
      }
      button:last-child {
        border-color: $blueColor;
        background-color: $blueColor;
      }
  }


  .bpx-block-dialog-wrap {
    color: #fff;

    .MuiCheckbox-root.Mui-checked { 
      color: $blueColor !important;
    }
    .MuiCheckbox-root {
      color: $whiteText !important;
    }

  }

  .bpx-block-dialog-wrap .bpx-close-regenerate {
    color: $whiteText;
  }
  .bpx-block-dialog-wrap .bpx-block-dialog-top, .bpx-block-dialog-wrap .bpx-bottom-regenerate, .bpx-block-dialog-bottom {
    border-color: $whiteText;
  }
  .bpx-block-dialog-wrap .bpx-footer-regenerate button {
    background-color: $blueColor;
  }
  
  .MuiDataGrid-cellContent.forDarkMode {
    color: #fff !important;
  }

  .bpx-group-left svg {
    color: $whiteText;
  }
  .bpx-btn-rates-add  svg {
    color: $whiteText;
  }

  //adwad
  .bpx-dialog-status-bg .bpx-dialog-status-header {
    border-color: $whiteText;
  }
  .bpx-dialog-status-block {
    color: $whiteText;
  }
  .bpx-dialog-status-bg .bpx-dialog-status-footer button {
    border-color: $whiteText;
    color: $whiteText;
  }

  // Migration 
  .bpx-upload-modal {
    background-color: $borderColorTable;
  } 
  .bpx-upload-header {
    color: $whiteText;
    border-color: $whiteText;
  }
  .bpx-upload-close svg {
    color: $whiteText;
  }
  .bpx-upload-content .subtotal-filter-label {
    color: $whiteText;
  }
  .bpx-upload-autocomplete {

    .subtotal-filter-field .MuiOutlinedInput-notchedOutline {
      border-color: $lineColor !important;
    }
  }
  .bpx-optionModal-section {

      .toolbarIconsBlock button {
        border-color: $lineColor;
      }
  }

  .bpx-upload-footer {

      .MuiButton-outlined.MuiButton-outlinedPrimary {
        color: #65bff2;
        border-color: #65bff2;
      }

      .MuiButton-outlined.MuiButton-outlinedPrimary.Mui-disabled {
        color: rgba(255, 255, 255, 0.3);
        box-shadow: none;
        border-color: rgba(255, 255, 255, 0.3);
        background-color: rgba(255, 255, 255, 0.12);
      }
      

  }
  .bpx-upload-name {
    color: $whiteText;
  }
  
  .bpx-simulation-content {

    .MuiDataGrid-cell {
      border-color: $lineColor !important;
    }
    .MuiDataGrid-columnHeaders {
      background-color: $lineColor;
    }
    .MuiDataGrid-main {
      border-color: $lineColor !important;
    }
    
  }

  .block-title-simulation {
    color: $whiteText;
  }
  .bpx-simulation-total-number {
    color: $whiteText;
  }
  .bpx-simulation-footer {

    .MuiButton-outlined.MuiButton-outlinedPrimary {
      color: #65bff2;
      border-color: #65bff2;
    }

  }


  // BP Detail Accordion Fetch
  .modal-rates-header {
    color: $whiteText;

      .modal-rates-title {
        border-color: $whiteText;
      }
  }
  .modal-rates-footer {

    .MuiButton-outlined.MuiButton-outlinedPrimary {
      color: #65bff2;
      border-color: #65bff2;
    }

  }

  .bpx-fetch-adding-btn {

      svg {
        color: #fff;
      }
  }
  .modal-rates-content-remove {
    color: $whiteText;
  }


  .toolbarIconsBlock span.MuiButton-startIcon {
    z-index: initial;
  }

  h2.MuiTypography-root.MuiTypography-h6 {
    color: $whiteText;
  }

  // AutoComplete 
  .MuiChip-filled {
    color: $whiteText;  
  }

  .dialog-fonts .MuiDialogActions-root .MuiButton-outlined.MuiButton-outlinedPrimary {
    color: $whiteText;
    background-color: transparent;
  }
  .MuiDialogActions-root .MuiButton-contained.MuiButton-containedPrimary {
    background-color: $blueColor;
  }

  .MuiDialogActions-root {

    .MuiButton-outlined.MuiButton-outlinedPrimary {
      color: $blueColor;
      border-color: $blueColor;
    }
  }
  .bpx-modal-delete-item {
    color: $whiteText;
  }
  // Auth Modal 
  .bpx-userid-modal {

      svg {
        color: $lineColor;
      }

      .bpx-userid-section {

          .MuiButton-containedError {
            background-color: $lineColor;
          }
          .MuiButton-containedPrimary {
            background-color: $blueColor;
          }
      }
  }

  .toolbarBlock .block-switch .MuiFormControlLabel-root {
    border-color: $borderColorTable;
  }
  .block-switch .MuiSwitch-track {
    background-color: $lineColor !important;
  }

  .MuiButton-startIcon-closeAO {

      img {
        filter: invert(1);
      }

  }
  .MuiButton-startIcon-find-product {
    
    img { 
      content: url('../../assets/images/find_product_dark_mode.png');
    }

  }
  //Close icon in modals 
  .close-for-modal-window {
    
    .MuiIconButton-root {
      color: $whiteText;
    }
  }

  //Accrual Posting 
  .bpx-select-item.abort {
    color: $blueColor;
    font-weight: normal;
  }
  .bpx-select-item.cancel {
    color: $blueColor;
    font-weight: normal;
  }

  .bpx-select-item.cancel:disabled,
  .bpx-select-item.cancel[disabled] {
    color: rgb(144, 144, 144);
    cursor: auto
  }

  .block-cancel-modal {
    color: $whiteText;

      .close-cancel-modal svg {
        color: $whiteText;
      }
      .cancel {
        color: $whiteText;
      }
  }

  //Toolbar
  .toolbarIconsBlock span.MuiButton-startIcon {
    background-color: #3c3b39;

      svg {
        color: $whiteText;
      }
  }
  .toolbarIconsBlock span.MuiButton-startIcon {
    height: 16px;
  }
  .toolbarIconsBlock .toolbarMoreHorizIcon {
    background-color: transparent;
    border-color: $borderColorTable;

      .MuiSvgIcon-root {
        color: $whiteText;
      }
  }

  //Cust
  .darkmode-for-svg .MuiDataGrid-treeDataGroupingCellToggle .MuiSvgIcon-root {
    color: $whiteText;
  }

  //Adjustment 
  .adjustment-summary {
    background-color: transparent;
  }
  .table-head-dark-mode {
    background-color: inherit !important;
  }
  .MuiPaper-root.MuiPaper-elevation .MuiTable-root th {
    background-color: #201f1d;
  }
  .MuiInputBase-input {
    border-color: $borderColorTable !important;
  }

  //Help Component 
  .block-help .block-header-help {
    color: $whiteText;
  }
  .block-help .block-header-help:after {
    border-color: $whiteText;
  }
  .block-help .block-middle-help {
    color: $whiteText;
  }
  .block-help .section-text-help {
    color: $whiteText;
  }
  .block-help .section-icon-help {
    color: $whiteText;
  }
  .block-help .section-help {
    border-color: $whiteText;
  }
  .block-help .block-header-help span {
    color: $blueColor;
  }
  .block-help .section-help:hover {
    border-color: $blueColor;
  }
  .block-help .section-help:hover .section-icon-help {
    color: $blueColor;
  }
  .block-help .section-help:hover .section-text-help {
    color: $blueColor;
  }

  //Switch
  .block-switch {

    .MuiTypography-root {
      color: $whiteText;
    }
    .MuiSwitch-track {
      background-color: $whiteText;
      opacity: 1;
    }
    .Mui-checked + .MuiSwitch-track {
      opacity: 1;
    }
    .Mui-checked .MuiSwitch-thumb {
      color: $whiteText !important;
    }
  }


  .MuiCircularProgress-indeterminate {
    color: $blueColor;
  }
  .MuiLinearProgress-colorPrimary .MuiLinearProgress-bar {
    background-color: $blueColor;
  }
  input.Mui-disabled {
    -webkit-text-fill-color: #ccc;
  }

  // Radio button
  .bpx-config-right {

    .PrivateSwitchBase-root {

      .MuiSvgIcon-root {
        color: $whiteText !important;
      }
      
    }
  }

  // Checkbox
  .MuiCheckbox-root {
    color: $lineColor !important;
  }

  // 404
  .notfoundpage_hide {
    display: none;
  }
  .page_404 {
    background-color: $mainBackroundColor;
    color: $whiteText;
  }
  .contant_box_404 {
    margin-top: 0;
  }
  .link_404 {
    background-color: $blueColor;
  }

  .bpx-darkmode-switcher {

      svg {
        color: $whiteText;
      }
  }
  .bpx-logo-leftside {
    background-image: url("../../assets/images/bpx-orange.png");
    background-size: 78%;
    background-position-x: 28%;
    background-position-y: -20%;
    border: none;
  }
  .bpx-logo-rightside {
    background-image: url("../../assets/images/MarsLogo.png");
    background-size: 80%;
    
  }


  .MuiDataGrid-main, .MuiDataGrid-cell {
    border-color: $borderColorTable !important;
  }
  .customSelectTable .MuiSelect-iconOutlined  {
    top: calc(50% - 10.3px);
    height: 21px;
  }
  .MuiPaper-elevation .MuiDialogContent-root {
    color: $whiteText;
  }

  .MuiDialogActions-root {

    .MuiGrid-root {

        .MuiButton-outlined {
          border-color: $blueColor;
          color: $whiteText;
        }
        .MuiButton-contained {
          background-color: $blueColor;
          color: $whiteText;
        }
    }
  }

  //Orange Color
  .customSelectTable.Mui-focused .MuiSelect-select {
    color: $blueColor !important;
  }
  .customLabelTable.Mui-focused {
    color: $whiteText !important;
  }
  .customSelectTable.Mui-focused .MuiOutlinedInput-notchedOutline {
    color: $blueColor !important;
  }
  .customSelectTable .MuiSelect-iconOpen.MuiSelect-iconOutlined {
    color: $lineColor !important;
  }
  .bpx-header-link {
    color: $whiteText;

      &:hover {
        color: $blueColor;
      }
  }
  .dark-mode-link-color {
    color: $blueColor !important;
  }

  //Icon detail 
  .bpx-icon-section svg {
    color: $whiteText;
  }
  .bpx-config-left .MuiListItem-root:hover .MuiTypography-root {
    color: $blueColor;
  }

  // Select 
  .customSelectTable.Mui-focused .MuiSelect-select {
    border-color: $borderColorTable !important;
  }
  .customOptionTable svg {
    color: $whiteText;
  } 
  .MuiInput-underline:after {
    border-color: $blueColor !important;
  }
  .MuiDataGrid-columnSeparator--resizable {
    color: $whiteText !important;
  }

  //Header 
  .bpx-header-container {

    .bpx-header-left-userdata {
      color: $colorTextUserName;
    }
    .bpx-header-top {
      background-color: $mainBackroundColor;
    }
    .MuiToolbar-root.MuiToolbar-regular {
      background-color: $colorBackgroundHeader;
      border-bottom: 1px solid $colorBorderHeader;

        .bpx-btn-header {
         color: $whiteText !important;
        }
    }
    .bpx-header-right-icon {

        svg {
          color: $whiteText;
        }
    }

  }
  .bpx-switch-custom {
    .MuiSwitch-root .MuiSwitch-thumb {
      background-color: $blueColor;
    }
  }
  
  


  // Panel
  .breadCrumbsBlock li a, .breadCrumbsBlock li p, 
  .breadCrumbsBlock li, .toolbarTitle, .toolbarIconsBlock button {
    color: $whiteText;
  }
  .toolbarIconsBlock button {
    border-color: $borderColorTable;
  }


  // Table
  .MuiDataGrid-root {
    color: $whiteText;
  }
  // .tableContainer .MuiDataGrid-root {
  //   background-color: $m;
  // }
  .MuiDataGrid-columnHeaders{
    background-color: $parerBackground;
    color: $whiteText;
    border-color: $borderColorTable;

    .MuiDataGrid-menuIconButton, .MuiIconButton-sizeSmall {
      color: $whiteText;
    }
    
  }

  .bpx-modal-find-product .MuiDataGrid-columnHeaders,
  .bpx-change-log .MuiDataGrid-columnHeaders {
    background-color: $colorBackgroundHeaderPopup;
  }

  .tableContainer .toolbarContainerBlock {
    background-color: $mainBackroundColor;
  }


 .bpx-modal-find-product .tableContainer .toolbarContainerBlock {
    background-color: $parerBackground;
  }

  .MuiDataGrid-paper {

    .MuiFormControlLabel-label.Mui-disabled {
      color: rgba(255,255,255, 0.6) !important;
    }
  }

  .MuiDataGrid-overlay {
    background-color: $backgroundOverlay;
  }
  

  .MuiList-root.MuiMenu-list {
    background: $parerBackground;
    color: $whiteText;
  }
  .customSelectTable .MuiSelect-iconOutlined {
    right: 1.7px;
    background-color: $parerBackground;
  }
  .MuiFormLabel-colorPrimary {
    color: $whiteText;
  }
  .MuiFormLabel-colorPrimary.Mui-focused {
    color: $whiteText;
  }
  .MuiTabs-indicator {
    background-color: $blueColor;
  }
  .Mui-checked+.MuiSwitch-track {
    background-color: $blueColor !important;
  }
  .MuiSwitch-thumb {
    color: $blueColor !important;
  }
  .MuiDataGrid-panelHeader {

    .MuiInput-underline::before {
      border-color: $whiteText;
    }
    .MuiInput-underline::after {
      border-color: $blueColor;
    }

  }
  .MuiDataGrid-panelFooter {

    .MuiButton-textPrimary {
      color: $blueColor !important;
    }
  }
  
  .MuiDataGrid-menuList {
    background-color: $parerBackground;
    color: $whiteText;
  }
  .MuiDataGrid-filterForm  {

      .MuiSvgIcon-root, .MuiIconButton-sizeSmall {
        color: $whiteText;
      }
      .MuiInput-underline:before {
        border-color: $blueColor;
      }
      .MuiInput-underline:hover:before {
        border-color: $blueColor;
      }
  }

  .MuiDataGrid-pinnedColumnHeaders, .MuiDataGrid-pinnedColumns {
    background-color: $backgroundOverlay;
  }


  

  // Filter
  .MuiAccordionSummary-expandIconWrapper, .subtotal-accordion .MuiAccordionSummary-root {
    color: $whiteText;
  }
  .subtotal-accordion {
    background-color: $mainBackroundColor;
    color: $whiteText;
  }
  .subtotal-filter-section {
    color: $whiteText !important;
  }
  
  //MuiAutoComplete
  .MuiAutocomplete-paper {
    background-color: $parerBackground;
  }
  .MuiAutocomplete-clearIndicator {
    color: $whiteText;
  }
  .MuiAutocomplete-popupIndicator {
    color: $whiteText;
  }
  .subtotal-filter-name, .subtotal-filter-dots,
  .subtotal-filter-no-multiple {
    color: $whiteText;
  }
  .MuiAutocomplete-tag {
    background-color: $lineColor;

      .MuiChip-label {
        color: $whiteText !important;
      }
      svg {
        color: $whiteText !important;
      }
  }
  .MuiAutocomplete-hasClearIcon {
    color: $whiteText;
  }
  .MuiAutocomplete-inputRoot {
    background-color: $parerBackground;
  }
  .MuiAutocomplete-input {
    color: $whiteText !important;
  }
  .MuiAutocomplete-noOptions {
    color: $whiteText;
  }
  .MuiCheckbox-colorPrimary {
    color: $whiteText;
  }


  //DatePicker
  .subtotal-filter-field {
    
    .MuiSvgIcon-root {
      color: $lineColor;
    }
    .MuiInputBase-root {
      background-color: transparent;
    }
    .MuiInputBase-input {
      color: $whiteText !important;
    }
      
  }
  .MuiCalendarPicker-root {
    background-color: $parerBackground;
    color: $whiteText;

      .MuiDayPicker-weekDayLabel {
        color: $whiteText;
      }
      .MuiSvgIcon-root {
        color: $whiteText;
      }
      .MuiPickersDay-root:hover {
        background-color: $blueColor;
        color: $whiteText;
      }
      .MuiPickersDay-today {
        background-color: $colorOrange;
        color: $whiteText;
      } 
  }
 

  


  // Columns Panel 
  .MuiDataGrid-paper {
    background-color: $parerBackground;
    color: $whiteText;

      .MuiFormLabel-colorPrimary, .MuiInputBase-input {
        color: $whiteText;
      }
  }

  
  // Menu Sandwich
  .bpx-header-menu-dropdown .MuiMenu-paper {
    border: none;
  }
  .bpx-header-nested-popover {
    border: none;

       ul {
        border-color: $parerBackground !important;
       }

  }


  // Breadcrum
  .breadCrumbsBlock li:first-child a::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 9px;  
    // margin-right: 8px;
    margin-left: 8px;
    background: url(../../assets/images/arrow-white.svg) no-repeat center center;
    background-size: cover;
  }
  
  
  // Modal window
  .MuiDialog-paperScrollPaper {
    background-color: $parerBackground;
  }
  .bpx-userid-name, .bpx-userid-title {
    color: $whiteText;
  }

  .MuiOutlinedInput-root {
    color: $whiteText;
    border-color: $whiteText;

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: $blueColor;
    }

  }

  // Adjustment
  .MuiPaper-root.MuiPaper-elevation {
    

      .MuiTable-root {
        border-color: $borderColorTable !important;
        background-color: $mainBackroundColor;
        

          tr, td, th {
            border-color: $borderColorTable !important;
            color: $whiteText !important;
          }
      }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $lineColor;
  }
  .subtotal-filter-field .MuiOutlinedInput-notchedOutline {
    border-color: $borderColorTable !important;
  }
  .MuiIconButton-edgeEnd {
    color: $whiteText;
  }
  .bpx-userid-section {

      .MuiButton-outlinedPrimary {
        background-color: $blueColor;
        color: $whiteText;
      }
  }



  // Modal User Preference
  .css-ytibio {
    background-color: $parerBackground;
    color: $whiteText;
    border-color: $parerBackground;

      .MuiSvgIcon-root, .MuiSelect-icon {
        color: $whiteText;
      }
      .MuiButtonBase-root {
        color: $whiteText;
      }
      .MuiButtonBase-root.Mui-selected {
        color: $blueColor;
      }
      
      .MuiTextField-root {
        color: $whiteText;
      }
  }
  .MuiAutocomplete-option {
    color: $whiteText;
  }


  // Excel Export 
  .dialog-fonts {
    color: $whiteText;

      .MuiButton-outlinedPrimary {
        color: $whiteText;
        background-color: $blueColor;
      }
  }

  // Modal window Edit
  .MuiSelect-iconOutlined {
    color: #7b7b7b;
  }

  .MuiInputBase-root.Mui-disabled {

      .MuiOutlinedInput-notchedOutline {
        border-color: $colorDisabled;  
        color: $colorDisabled;
      }

      .MuiSelect-iconOutlined.Mui-disabled {
        color: $colorDisabled;
      }

      .MuiSelect-select {
        -webkit-text-fill-color: $colorDisabled;
      }

  }

  .MuiInputLabel-formControl.Mui-disabled {
    color: $colorDisabled;
  }



  // Detail Page
  .bpx-block-detail {
    color: $whiteText;
  }
  .bpx-top-detail {
    background-color: $parerBackground;
    color: $whiteText;
  }

  .bpx-modal-find-product .bpx-top-detail {
    background-color: $colorBackgroundHeaderPopup;
  }
  .bpx-right-side .MuiAccordion-root {
    background-color: transparent;
  }
  .bpx-accordion-header {
    // background-color: $parerBackground;
    border-color: $borderColorTable !important;

    .bpx-arrow-icon {
      color: $whiteText;
    }
  }
  .bpx-accordion-detail,
  .bpx-change-log {
    background-color: $mainBackroundColor;
  }


  

  // Modal Close AO
  .MuiModal-root .MuiBox-root {
    background-color: $parerBackground;

    .MuiButton-outlinedPrimary {
      background-color: $blueColor;
      color: $whiteText;
      border-color: transparent;
    }
  }

  .MuiMenu-paper.MuiPaper-elevation {
    background-color: transparent;
  }



  // Config
  .bpx-config-left .MuiListItem-root {
    background-color: $parerBackground;
    color: $whiteText;
  }
  .bpx-config-left a {
    color: $whiteText;
  }
  .bpx-config-datagrid-bottom .MuiSvgIcon-root {
    color: $whiteText;
  }

  

  .bpx-config-datagrid {

    .MuiCheckbox-root.Mui-checked { 
      color: $blueColor;
    }
    .MuiCheckbox-root {
      color: $whiteText;
    }

  }

  .bpx-chart-section {
    background-color: $parerBackground;
  }

  // Chart
  .bpx-chart-inner, .bpx-chart-plan-account {
    background-color: $parerBackground;
  }

  .bpx-chart-plan-account-container {
    border-color: $borderColorTable;
  }

  .apexcharts-text {
    fill: $whiteText !important;
  }

  .apexcharts-legend-text {
    color: $whiteText !important;
  }

  .MuiInputBase-root:has(> input:-webkit-autofill):not(.bpx-login-input){
    background-color: transparent;

    .MuiSvgIcon-root {
        color: $whiteText
      }
      
      input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:focus, 
      input:-webkit-autofill:active {
        -webkit-background-clip: text;
        -webkit-text-fill-color: $whiteText;
      }
    }  

   .MuiDataGrid-booleanCell{
    color: $whiteText !important
   }
  .bpx-trade {
    .MuiButtonBase-root.Mui-disabled {
      color: rgba(255, 255, 255, 0.3);
      box-shadow: none;
      border-color: rgba(255, 255, 255, 0.3);
      background-color: rgba(255, 255, 255, 0.12);
    }
    .bpx-trade-header {
      background: $parerBackground;
    }
    .trade-bpx-detail{
      background: $colorBackgroundHeaderPopup
    }
    .bpx-trade-icon-more-information .MuiSvgIcon-root{
      color: rgb(128 144 151) !important
    }
    .MuiDataGrid-cellContent .trade-ballance-row.border {
      border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  }

  button:disabled,
  button[disabled] {
    color: rgba(255, 255, 255, 0.3) !important;
    box-shadow: none;
    border-color: rgba(255, 255, 255, 0.3) !important;
    background-color: rgba(255, 255, 255, 0.12) !important;
  }
  

  //trade
  .tradeToolbarIconsBlock {

    button:disabled,
    button[disabled] {
      color: grey;
  
      span .MuiSvgIcon-root {
        color: grey;
      }
    }
    
  }
  .tradeToolbarMenuItem {
    .MuiButtonBase-root.MuiButton-root,
    .text-menu-toolbar {
      color: $whiteText !important;
    }

    .icon-menu-toolbar,
    .MuiButton-startIcon {
      color: $whiteText
    }
  }
  .trade-summary{
    .MuiDataGrid-columnHeaders  {
      background-color: $colorBackgroundHeaderPopup;
    }

    .data-grid-1, .data-grid-2{
      background-color: $mainBackroundColor
    }
  }
  .bpx-config-separate-page {

    .MuiButton-outlined.MuiButton-outlinedPrimary {
      color: $blueColor;
      border-color: $blueColor;
    }

    .MuiButton-outlined.MuiButton-outlinedPrimary.Mui-disabled {
      color: rgba(255, 255, 255, 0.3);
      box-shadow: none;
      border-color: rgba(255, 255, 255, 0.3);
      background-color: rgba(255, 255, 255, 0.12);
    }


    .MuiButton-contained {
      background-color: #1976d2;
      color: $whiteText;
    }

    .MuiButton-contained.Mui-disabled {
      color: rgba(255, 255, 255, 0.3);
      background-color: rgba(255, 255, 255, 0.12);
    }
    
    .MuiDialog-paper.light{
      background-color: white;
    }

  }

  .toolbarGridColumnsButton button[disabled],
  .toolbarGridColumnsButton button[disabled] svg {
    color: rgb(144, 144, 144);
    cursor: auto
  }
}


.bpx-switch-custom {
  flex-flow: row wrap !important;
  cursor: pointer;
  align-items: center;
  min-height: 38px;
}
