.bpx-config-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.bpx-config-left {
  width: 25%;

  .MuiListItem-root {
    padding: 0;
    color: rgb(89, 89, 89);
    background-color: #f1f1f1;
    border-radius: 3px;
    margin-bottom: 0.5rem;
    padding-left: .2rem;

    &:hover {

      .MuiTypography-root {
        color: #f7941d;
      }
    }
  }

  a {
    color: rgb(89, 89, 89);
    text-decoration: none;
    padding-left: 1.1rem;
  }

  .MuiTypography-root {
    font-family: "Arial" !important;
    font-size: 0.75rem !important;
    padding-left: .2rem;
    transition: color .3s;
  }

  .MuiSvgIcon-fontSizeMedium {
    font-size: 0.75rem;
  }
}

.bpx-config-right {
  width: 70%;
}

.bpx-config-bu {
  margin-bottom: 20px;
}

.bpx-config-datagrid {

  .MuiDataGrid-root {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  }

  .bpx-config-datagrid-assigment {

    .MuiDataGrid-main {
      height: auto;
    }
  }
}


.bpx-config-right .bpx-config-datagrid .expiration-days-column-header .MuiDataGrid-columnHeaderDraggableContainer,
.bpx-config-datagrid .expiration-days-column-header .MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitleContainer {
  flex-direction: row;
  justify-content: flex-start;
}

.bpx-config-datagrid .MuiDataGrid-main .expiration-days-column-cell {
  flex-direction: row;
  justify-content: flex-start;
}

.bpx-config-datagrid-title {
  margin: 0;
  line-height: normal;
  margin-bottom: .5rem;
  font-family: "Arial";
}

.bpx-config-datagrid-btn {
  color: #000 !important;
  padding: 0 !important;
  min-width: auto !important;
  margin-top: 1rem !important;

  .MuiButton-iconSizeMedium {
    margin: 0;
  }

  .MuiSvgIcon-fontSizeMedium {
    font-size: 2rem !important;
  }
}

.bpx-config-datagrid-bottom {
  text-align: right;
}



.bpx-config-separate-page .file-name {
  color: grey;
  word-break: break-all;
}

.bpx-config-separate-page .grid-container {
  display: inline-grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px 20px;
  margin-top: 10px;
  justify-items: left;
  width: 730px;
  //align-items: center;
}

.bpx-config-separate-page .grid-item {
  text-align: left;
  width: 130px;
}

.bpx-config-separate-page .grid-container .MuiButton-root:not(.save) {
  width: 130px;
  height: 26px;
  font-size: 12px;
}


.bpx-config-separate-page .wide-column {
  grid-column: span 5;
  font-weight: bold;
  margin-top: 10px
}

.bpx-config-separate-page .file-input {
  display: none;
}


.bpx-config-separate-page .error {
  color: red;
  font-size: 12px;
}

.bpx-config-separate-page .file-input-label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.bpx-config-separate-page .info {
  color: grey;
  font-style: italic;
  font-size: 12px;
  width: 730px;
  text-align: right;
  padding-top: 5px;
}

.bpx-config-separate-page .button-container {
  width: 730px;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  gap: 15px;
}
