.bpx-block-dialog-wrap {
  // padding: 1rem 1.5rem;
  padding: 0;

    .bpx-block-dialog-content{
      padding-top: 0;
      padding-bottom: 0; 
    }

    .bpx-header-regenerate {
      position: relative;
      text-align: center;
    }
    .bpx-title-regenerate {
      font-size: 1.25rem;
    }
    .bpx-close-regenerate {
      position: absolute;
      right: 0;
      top: 5px;
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;

        svg {
          font-size: 1.5rem;
        }
    }

    .bpx-block-dialog-top {
      border-top: 2px solid #ccc;
      padding-top: 0.625rem;
      margin-top: 0.625rem;

        .bpx-block-dialog-top-text {
          font-size: 1rem;
          text-align: center;

            span {
              display: block;
              font-style: italic;
              font-size: 0.813rem;
            }

        }


        .bpx-section-regenerate {
          display: flex;
          margin-top: 0.5rem;

            .bpx-left-regenerate {
              width: 250px;
            }
            .bpx-right-regenerate {
              min-width: 30px;
            }
        }

        
    }

    .bpx-bottom-regenerate {
      border-top: 2px solid black;
      padding-top: 0.625rem;
      margin-top: 0.625rem;


        .bpx-section-regenerate {
          display: flex;
          margin-top: 0.5rem;

            .bpx-left-regenerate {
              width: 280px;
            }
            .bpx-right-regenerate {

                .MuiCheckbox-root {
                  padding: 0;
                }
            }
            .bpx-desc-regenerate {
              display: block;
              font-size: 0.813rem;
            }

        }
   
    }

    .bpx-block-dialog-bottom {
      border-top: 2px solid black;
      padding-top: 0.625rem;
      margin-top: 0.625rem;
      font-style: italic;
      font-size: 0.9rem;
    }

    .recalculate-ao {
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 10px;
      margin-top: 0.5rem;
      align-items: center;

      .bpx-right-regenerate {
        .MuiCheckbox-root {
          padding: 0;
        }
      }
      .bpx-desc-regenerate {
        display: block;
        font-size: 0.813rem;
      }
    }


    .bpx-footer-regenerate {
      text-align: right;

        button {
          background-color: #1976d2;
          color: #fff;
          border-radius: 5px;
          border: none;
          text-transform: uppercase;
          // width: 150px;
          padding: 6px 1rem;
          line-height: 1.75;
          cursor: pointer;
          margin-top: 1rem;

          &:hover {
            background-color: #1565c0;
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
          }

        }

        button[disabled]{ 
          background-color: rgba(0, 0, 0, 0.26);
        }

        

    }
    

    

}

.bpx-block-closeoe {

    .dialog-fonts {
      padding: 1rem 1.5rem;
    }
    .MuiDialogTitle-root {
     text-align: center; 
    }
    .MuiDialogContent-root {
      text-align: center;
      padding: 0;
    }
    .MuiDialogTitle-root {
      border-bottom: 2px solid black;
      padding: 0;
      padding-bottom: 0.5rem;
    }
    .MuiDialogActions-root {
      padding: 0 !important;
      margin-top: 2rem;
    }

}

.custom-dialog-fonts {
  padding: 0.5rem 1rem;
  text-align: center;

  .close-for-modal-window {
    right: 10px;
    top: 9px;
  }
  .dialog-fonts-h2 {
    padding: 0;
    padding-bottom: 0.3rem;
    border-bottom: 2px solid black;
    text-align: center;
  }

}

