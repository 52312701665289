.bpx-header-container {
  padding: 0;
  margin: 0;
  background-color: white;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.bpx-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  min-height: 65px;
  position: relative;
}

.bpx-header-left {
  display: flex;
  flex-flow: row wrap;
}
.bpx-header-left-accrual {
  padding-right: 16px;

    p {
      margin: 0;
      font-size: 1rem;
      font-weight: 700;
      text-align: right;
      line-height: 1.5;
      letter-spacing: 0.00938em;
    }

}
.bpx-header-left-userdata {
  min-width: 150px;
  padding-left: 16px;
  border-left: 1px solid rgb(221, 221, 221);

    p {
      margin: 0;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0.00938em;
      color: #bbb;
    }

}

.bpx-header-center {
  // position: absolute;
  // right: 0;
  // left: 0;
  // margin: auto;
  // width: 315px;
   //315px; //350
   margin-left: -210px
}

.bpx-header-center a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //gap: 20px;
}

.bpx-header-center div {
  position: relative;
}


.bpx-header-center div:nth-child(even)::before {
  background-color: red;
  content: "";
  position: absolute;
  background: #ddd;
  width: 1px;
  height: 39px;
  top: 50%;
  transform: translateY(-50%);
}

.bpx-header-right {
  display: flex;
  flex-flow: row wrap;
  line-height: .6;
}


.bpx-header-menu-dropdown {
  
    .bpx-header-link {
      font-family: 'Montserrat' !important;
      font-size: 11px !important;
      font-weight: 500 !important;
      text-decoration: none;
      color: inherit;
    }

    .MuiMenu-paper {
      border: 1px solid rgb(225, 225, 225);
      border-radius: 3px;
    }

    ul {
      padding: 0;
      list-style: none;
    }

    .bpx-header-submenu-block {
      opacity: 0;
      display: none;
    }

    li {
      padding-left: 5px;
      padding-right: 5px;
      position: relative;
      background-color: transparent !important;
      
        &:hover {
          background-color: transparent;
        }
    
    }
    
    

}
.bpx-header-nested {
  padding-left: 5px;
  padding-right: 5px;

    p {
      padding: 0;
    }
    .MuiSvgIcon-root {
      display: none;
    }
    .bpx-header-svg {
      display: block;
    }
 
}

.bpx-header-link {
  font-family: 'Montserrat' !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  width: 100%;
  padding: 0 !important;
  display: block;

  &:hover {
    background-color: transparent;
    color: #1976d2;
  }

}

.bpx-header-nested-popover {

    ul {
      padding: 0;
      background-color: #fff;
      border: 1px solid rgb(225, 225, 225);
      border-radius: 3px;
      box-shadow: 1px 1.732px 3px 0px rgba(0, 0, 0, 0.1);
    }
    li {
      
    }
    li:hover {
      background-color: transparent;
    }

}


.bpx-header-svg {
  width: 15px !important;
  height: 13px !important;
  font-size: inherit !important;
  color: #666;
  margin-right: 10px;
}

.bpx-header-divider {
  margin: 0 !important;
}

.active-bu {

  .bpx-header-link {
    color: #f69e80;
  }
  
}


.bpx-logo-leftside {
  box-sizing: border-box;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-right: 16px;
  width: 190px;
  height: 39px;
  background: url(../../assets/images/bpx-transparent.png) no-repeat;
  display: inline-block;
}

.bpx-logo-rightside {
  box-sizing: border-box;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 38px;
  width: 127px;
  padding-left: 24px;
  background: url(../../assets/images/logo-transparent.png) no-repeat center right;
  background-size: contain;
  display: inline-block;
}
